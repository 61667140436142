.services-container {
   /* background-image: url("../../../../public/images/blue_fibre_optics_jj-ying-8bghKxNU1j0-unsplash.jpg");
    min-width: 100%;*/
    
}

.about,
.skills {
    text-align: left;
    margin-bottom: 0rem;
    margin-top: 0rem;
}

.about-title {
    text-align: center;
    color: #fffd0e;
    font-weight: bolder;
    margin-bottom: 2rem;
}

.about-content {
    margin-left: 3rem;
    margin-right: 8rem;
    text-align: left;
    display: flex;
    flex-direction: column;
    
}

.about-content .content p {
    margin-top: 0rem;
    color: #fffd0e!important;
}

.about-content h2 {
    margin-top: 1rem;
}

.about {
    margin-bottom: 1rem;
}

@media only screen and (max-width: 900px) {
    .about-content,
    .skills {
        margin-left: 1rem;
        margin-right: 1rem;
        text-align: left;
    }
    
}
