
* {
  box-sizing: border-box;
  font-family: sans-serif;
  /*background: #000000;*/
  /*background-image: url("../public/images/blue_fibre_optics_jj-ying-8bghKxNU1j0-unsplash.jpg");*/
  color: #fffd0e;
}

html, body, #root {
  padding: 0;
  margin: 0;
  height: 100%;
  scroll-behavior: smooth;
  background-image: url("../public/images/blue_fibre_optics_jj.jpg");
  min-width: 100%;
  opacity: 0.95;
}


.CookieConsent {
  box-shadow: 0 6px 10px #fffd0e, 0 6px 10px #fffd0e!important;
}

#rcc-decline-button:hover {
  color: white;
  /* border-color: darkviolet!important; */
  box-shadow: 0 15px 15px #fffd0e, 0 15px 15px #fffd0e!important;
  transition: transform ease-in-out 0.25s;
  transform: scale(1.1);
}

#rcc-confirm-button:hover {
  color: white!important;
  /* border-color: darkviolet!important; */
  box-shadow: 0 15px 30px #fffd0e, 0 15px 15px #fffd0e!important;
  transition: transform ease-in-out 0.25s;
  transform: scale(1.1);
}