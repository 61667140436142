.contact-title {
  text-align: center;
  color: #fffd0e!important;
  font-weight: bolder;
  /*margin-bottom: 2rem;*/
}

div.contact-page-container {
  display: flex;
  width: auto;
  margin-bottom: 8rem;
}

.statement-container {
  margin-top: 2rem;
  margin-right: 8rem;
}

.statement-container h2 {
  margin-top: 5rem;
}

.statement-container p {
  margin-right: 1rem;
  margin-left: 1rem;
}

.contact {
  padding-top: 2rem;
  display: flex;
  flex-direction: row;
}

.form-container {
  margin-top: 5rem;
  margin-left: 0;
  color: black!important;
}

input[type=text], select, textarea {
  width: 100%;
  padding: 12px;
  border: 2px solid #fffd0e;
  border-radius: 10px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
  background-color: #7c7e7c;
  color: black;
  font-weight: bolder;
}

::placeholder {
  color: rgb(13, 13, 17);
  opacity: 0.7; /* Firefox */
  font-weight:lighter;
}

::-ms-input-placeholder { /* Edge 12-18 */
  color: rgb(9, 9, 10);
}

.main-form {
  margin: 1rem;
  margin-left: 10rem;

}

.hello {
  padding-top: 6rem;
  margin-left: 0;
}

.form input {
  display: block;
  margin-top: 5px;
  margin-bottom: 5px;
  width: 30rem;
}

.message {
  width: 30rem;
  height: 30vh;
}

textarea.message {
  overflow-wrap: normal !important;
}

.submit {
  border: 2px solid #fffd0e;
  color: black;
  font-weight: bolder;
  border-radius: 5px;
  background-color: #7c7e7c;
}

.submit:hover {
  /*color: rgb(239, 231, 239);*/
  box-shadow: 0 6px 40px #15e231, 0 6px 15px #999;
  transition: transform ease-in-out 0.25s;
  transform: scale(1.25);
  
}
/*
.contact-image-container {
  display: flex;
  justify-content: center;
}


.contact-image {
  background-image: url("../../../../public/images/macbook_half_open_dhaval-parmar-dnPniNPUe4o-unsplash\ \(Phone\).jpg");
  background-size:cover;
  height: 10rem;
  width: 30rem;
  
}*/



@media only screen and (max-width: 1000px) {

  .statement-container {
    margin-top: 2rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
  }

  .form,
  .form input,
  .message {
    width: 100%;
    margin: 0;
  }

  div.contact-page-container {
    flex-direction: column;
    margin-bottom: 0;
  }
  .statement-container p{
    margin-top: 6vh;
    margin-right: 1rem;
    margin-left: 1rem;
    text-align: center;
  }


}