/* .carousel-page {
justify-content: center;

} */


.carousel-container {
  justify-items: center;
  margin: 0;
  padding: 0;
  justify-content: center;
  align-items: center;
  /* width: 100%;
  height: 20rem; */
  opacity: 0.8;
}

.swiper {
  width: 100%;
  height: auto;
  position: absolute;
  left: 0%;
  top: 10%;
  margin-left: 0;
  margin-top: 0;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
}

.swiper-slide img {
  display: block;
  width: 100%;
}

.slider-controller {
  margin: 5rem;
}

@media only screen and (max-width: 600px) {

  .carousel-page {
    display: none;
  }

}