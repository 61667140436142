.home-container {
  /* display: flex; */
  /* width: 100%; */
  /* margin: 0; */
  /* padding: 0; */
  /* justify-content: center; */

}

.content-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0;
  padding: 0;
  width: 100%;
}

.logo-container {
  width: 100%;
}

.logo {
  height: 30vh;
  margin-left: 28%;
  margin-bottom: 4rem;
}

.blurb-container{
  width: 100%;
  display: flex;
  margin: 0;
  padding: 0;
  justify-content: center;
}

/*.techybox {
  max-width: 60%;
  max-height: 40%;
  margin: 2rem;
}*/

.blurb {
  text-align: left;
  padding: 1rem;
  margin-top: 1rem;
 /* margin-left: 3rem;
  margin-right: 3rem;*/
  min-width: 60%;
}

@media only screen and (max-width: 600px) {
  .logo {
 /*   max-width: 3rem;
    max-height: 2rem;*/
    display: none;
  }

  .content-container {
    width: auto;
    display:inline-block;
    justify-content: center;
  }

  .blurb {
    padding: 1rem;
  }
}