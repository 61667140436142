body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

.title-box {
  display: flex;
}

.sheild {
height: 3rem;
padding: 8px;
}

.sheild:hover {
  scale: 1.25;
}

.heading {
color: #fffd0e!important;
font-weight: bold;
text-align: center;
}

.nav a {
  color: #fffd0e;
}

.header-link:hover {
  color:rgb(72, 12, 85)!important;
  transition: transform ease-in-out 0.25s;
  transform: scale(1.25);
}

@media only screen and (max-width: 600px) {
  .sheild {
    height: 10rem;
    padding: 1rem;
  }
}